<template>
  <div class="d-flex align-items-center justify-content-center flex-column">
    <div class="error-page-title h2 mt-5 mb-1">
      The quiz is temporarily unavailable.
    </div>
    <div class="error-page-description mt-5 h5">
       Please make sure that your browser has cookies enabled and try again later. 
    </div>
    <div class="error-page-logo mt-5">
      
          <div class=" pl-5 mt-5" style="text-align:center;">
            <a href="https://www.quizell.com" target="_blank" >
                <LazyImage :src="'https://images.quizell.com/website%2FGroup%2069231%20(1).svg'" alt="" style="width:200px;height:auto" />
            </a>
         
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.error-page-title{
  color: rgb(77, 27, 126);
    font-weight: 700;
    font-size: 34px;
    text-align: center;
}
.error-page-description{
  color: rgb(179, 175, 182);
}
</style>